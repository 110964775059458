<template>
  <div class="mx-auto max-w-3xl">
    <PhoneHeader :admin="true" />
    <main class="py-10">
      <div class="my-12 px-4 sm:px-6 lg:px-8">
        <slot />
      </div>
    </main>
  </div>
</template>
